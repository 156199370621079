<template>
  <section v-if="!isRewardsDisabled" class="section rewards-section">
    <div class="wrapper">
      <div class="slider-header">
        <div class="left">
          <div class="bottom">
            <h2 class="slider-title">{{ $t("Battle pass") }}</h2>
          </div>
        </div>
      </div>

      <div class="description">
        {{ $t("Earn exclusive rewards by completing challenges and progressing through tiers.") }}
      </div>

      <div class="rewards-slider">
        <swiper
          :modules="swiperModules"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
          :slidesPerView="'auto'"
          :spaceBetween="15"
          :slidesPerGroup="slidesToScroll()"
          @swiper="onSwiper"
        >
          <swiper-slide
            v-for="(reward, index) in rewardsData?.reward_tiers"
            :key="reward.id"
            class="reward-card"
          >
            <div
              class="card-wrapper"
              :class="{
                claimable: isAuth && isClaimable(reward, index),
                claimed: isAuth && isRewardClaimed(reward.id),
              }"
            >
              <div class="card-content">
                <div
                  class="card-image-container"
                  :class="{ 'blurred-content': !isAuth }"
                >
                  <img
                    :src="reward.image_url"
                    :alt="reward.reward_name"
                    class="card-image"
                  />
                </div>
                <h3 :class="{ 'blurred-content': !isAuth }">
                  {{ reward.reward_name }}
                </h3>
                <template v-if="isAuth">
                  <button
                    v-if="
                      isClaimable(reward, index) && !isRewardClaimed(reward.id)
                    "
                    class="claim-button"
                    :class="{
                      'claim-button-active': isClaimable(reward, index),
                      'claim-button-disabled':
                        !isClaimable(reward, index) ||
                        isRewardClaimed(reward.id),
                    }"
                    @click="handleClaim(reward)"
                    :disabled="
                      !isClaimable(reward, index) || isRewardClaimed(reward.id)
                    "
                  >
                    <span v-if="isRewardClaimed(reward.id)">{{
                      $t("Claimed")
                    }}</span>
                    <span v-else>{{ $t("Claim") }}</span>
                  </button>
                  <span
                    v-else-if="
                      !isClaimable(reward, index) && !isRewardClaimed(reward.id)
                    "
                    class="upgrade-text"
                  >
                    {{ $t("Upgrade to claim") }}
                  </span>
                  <span
                    v-else-if="isRewardClaimed(reward.id)"
                    class="claimed-text"
                  >
                    {{ $t("Claimed") }}
                  </span>
                </template>
                <button
                  v-else
                  class="sign-in-button-card"
                  @click="$emit('openSignIn')"
                >
                  {{ $t("Sign in") }}
                </button>
              </div>
            </div>
            <div
              class="progress-bar-wrapper"
              :class="{ 'blurred-content': !isAuth }"
            >
              <div class="progress-bar">
                <div
                  class="progress-bar-fill"
                  :style="{ width: `${getProgressWidth(reward)}%` }"
                ></div>
                <div
                  class="progress-bar-divider"
                  v-for="i in 9"
                  :key="`divider-${i}`"
                  :style="{ left: `${i * 10}%` }"
                ></div>
              </div>
              <div class="progress-bar-text">{{ getProgressText(reward) }}</div>
            </div>
          </swiper-slide>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </swiper>
      </div>

      <button
        v-if="isAuth"
        class="claim-all-button"
        @click="handleClaimAll"
        :disabled="!canClaimAll"
      >
        {{ $t("Claim") }}
      </button>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

export default {
  name: "RewardsSection",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    isAuth: {
      type: Boolean,
      required: true,
    },
    currencySymbol: {
      type: String,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      swiperModules: [Navigation],
      rewardsData: null,
      swiper: null,
      isRewardsDisabled: false,
    };
  },
  mounted() {
    this.fetchRewardsData();
    window.addEventListener("resize", this.handleWindowSizeChange);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  },
  computed: {
    canClaimAll() {
      if (!this.isAuth || !this.rewardsData) return false;
      return this.rewardsData.reward_tiers.some(
        (reward, index) =>
          this.isClaimable(reward, index) && !this.isRewardClaimed(reward.id)
      );
    },
  },
  watch: {
    isAuth: {
      handler(newIsAuth) {
        if (newIsAuth) {
          this.fetchRewardsData();
        }
      },
      immediate: false,
    },
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    handleWindowSizeChange() {
      if (this.swiper) {
        this.swiper.params.slidesPerGroup = this.slidesToScroll();
        this.swiper.update();
      }
    },
    slidesToScroll() {
      let containerWidth;
      if (innerWidth >= 1200) {
        containerWidth = 1200;
      } else {
        containerWidth = innerWidth - 32;
      }
      const cardWidth = 228;
      const gap = 15;
      return Math.floor((containerWidth + gap) / (cardWidth + gap));
    },
    async fetchRewardsData() {
      try {
        const url = this.isAuth
          ? `${process.env.VUE_APP_API}rewards?currency=${this.currencyCode}`
          : `${process.env.VUE_APP_API}rewards/guest?currency=${this.currencyCode}`;
        const response = await this.$http.get(url);
        if (response.status === 200) {
          this.rewardsData = response.data.payload;
          this.isRewardsDisabled = false;
        }
      } catch (error) {
        if (
          (error.response && error.response.status === 403) ||
          error.response.data.message === "Rewards feature is disabled."
        ) {
          this.isRewardsDisabled = true;
        } else {
          console.error("Error fetching rewards data:", error);
          this.$toast.error(this.getErrorMessage(error));
        }
      }
    },
    isRewardClaimed(rewardId) {
      return this.rewardsData?.claimed_rewards.includes(rewardId);
    },
    isClaimable(reward, index) {
      if (!this.isAuth) {
        return false;
      }
      if (this.isRewardClaimed(reward.id)) {
        return false;
      }

      for (let i = 0; i < index; i++) {
        if (!this.isRewardClaimed(this.rewardsData.reward_tiers[i].id)) {
          return false;
        }
      }
      // Convert to numbers before comparison
      const claimable =
        Number(this.rewardsData.spent_amount) >= Number(reward.amount);
      return claimable;
    },
    getProgressWidth(reward) {
      if (!this.isAuth) return 0;
      const progress = (this.rewardsData.spent_amount / reward.amount) * 100;
      return Math.min(progress, 100);
    },
    getProgressText(reward) {
      if (!this.isAuth) {
        return `0/${Number(reward.amount).toFixed(0)}${this.currencySymbol}`;
      }
      const spent = Math.min(
        Number(this.rewardsData.spent_amount),
        Number(reward.amount)
      );
      return `${spent.toFixed(0)}/${Number(reward.amount).toFixed(0)}${
        this.currencySymbol
      }`;
    },
    async handleClaim(reward) {
      if (
        !this.isAuth ||
        !this.isClaimable(reward, this.rewardsData.reward_tiers.indexOf(reward))
      )
        return;

      try {
        const response = await this.$http.post(
          `${process.env.VUE_APP_API}rewards/claim?currency=${this.currencyCode}`,
          { reward_tier_id: reward.id }
        );

        if (response.status === 200) {
          this.$toast.success(response.data.message);
          this.fetchRewardsData();
        }
      } catch (error) {
        this.$toast.error(this.getErrorMessage(error));
      }
    },
    async handleClaimAll() {
      if (!this.canClaimAll) return;
      for (const reward of this.rewardsData.reward_tiers) {
        if (
          this.isClaimable(
            reward,
            this.rewardsData.reward_tiers.indexOf(reward)
          ) &&
          !this.isRewardClaimed(reward.id)
        ) {
          try {
            await this.handleClaim(reward);
          } catch (error) {
            console.error(`Failed to claim reward ${reward.id}:`, error);
          }
        }
      }
    },
    getErrorMessage(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const messageKey = error.response.data.message;
        if (this.$te(messageKey)) {
          return this.$t(messageKey);
        }
      }
      return error.response.data.message || this.$t("Something went wrong");
    },
  },
};
</script>

<style scoped>
.rewards-section .wrapper {
  position: relative;
  max-width: 1230px;
}

.rewards-section .slider-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 69px;
  margin-bottom: 10px;
  padding: 0 15px;
}

.rewards-section .slider-title {
  font-weight: 700;
  font-size: 48px;
  line-height: 69px;
  color: white;
}

.rewards-section .description {
  padding: 0 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: white;
  margin-bottom: 20px;
}

.rewards-section .swiper {
  padding: 15px;
}

.rewards-section .rewards-slider {
  position: relative;
  width: 100%;
  margin-bottom: 44px;
}

.rewards-section .reward-card {
  width: 228px;
}

.rewards-section .card-wrapper {
  padding: 24px;
  background: #20222f;
  border-radius: 16px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.rewards-section .card-wrapper.claimable {
  border-color: #6d82f2;
  box-shadow: 0px 0px 10px 10px rgba(109, 130, 242, 0.2);
}

.rewards-section .card-wrapper.claimed .claim-button {
  background: #36373e;
  cursor: not-allowed;
}

.rewards-section .card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rewards-section .card-image-container {
  width: 124px;
  height: 80px;
  margin-bottom: 20px;
}

.rewards-section .card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rewards-section h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: white;
  text-align: center;
  margin-bottom: 30px;
}

.rewards-section .claim-button {
  width: fit-content;
  height: 38px;
  padding: 6px 24px;
  border-radius: 12px;
  background: #36373e;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.rewards-section .claim-button-active {
  background: #6d82f2;
}

.rewards-section .claim-button-disabled {
  background: #36373e;
  cursor: not-allowed;
}
.rewards-section .sign-in-button-card {
  width: 180px;
  height: 38px;
  padding: 6px 24px;
  border-radius: 12px;
  background: #6d82f2;
  color: white;
  font-size: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  border: none;
}

.rewards-section .progress-bar-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 14px;
  position: relative;
}

.rewards-section .progress-bar {
  width: 100%;
  height: 32px;
  background: #36373e;
  border-radius: 8px;
  position: relative;
}

.rewards-section .progress-bar-fill {
  height: 100%;
  background: #6d82f2;
  border-radius: 8px;
  transition: width 0.3s ease;
}

.rewards-section .progress-bar-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.rewards-section .upgrade-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #6d82f2;
  display: block;
  text-align: center;
  margin-top: 10px;
}

.rewards-section .claimed-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: white;
  display: block;
  text-align: center;
  margin-top: 10px;
}

/* Dividers for the progress bar */
/* .rewards-section .progress-bar-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #20222f;
} */

.rewards-section .swiper-button-next,
.rewards-section .swiper-button-prev {
  color: #6d82f2;
  border: 1px solid #6d82f2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #16171c;
}

.rewards-section .swiper-button-next::after,
.rewards-section .swiper-button-prev::after {
  font-size: 16px;
}

.rewards-section .swiper-button-next.swiper-button-disabled,
.rewards-section .swiper-button-prev.swiper-button-disabled {
  display: none;
}

.rewards-section .swiper-button-prev {
  left: -60px;
}

.rewards-section .swiper-button-next {
  right: -60px;
}

.rewards-section .claim-all-button {
  width: 273px;
  height: 52px;
  border-radius: 12px;
  background: #6d82f2;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border: none;
  display: block;
  margin: 0 auto;
}

.rewards-section .claim-all-button:disabled {
  background: #36373e;
  cursor: not-allowed;
}

@media (max-width: 1200px) {
  .rewards-section .swiper-button-prev {
    left: 0px;
  }

  .rewards-section .swiper-button-next {
    right: 0px;
  }
}

.rewards-section .blurred-content {
  filter: blur(5px);
}
</style>
